// @flow
import React from 'react';
import checkSiteFromConfig from '../../utils/checkSiteFromConfig';
import useIsLabel from '../../hooks/Page/useIsLabel';

const scriptIds = Object.freeze({
  htz: 'GTM-WRMRM72',
  tm: 'GTM-KG6K4SP',
  hdc: 'GTM-5S78XLS',
});

const { siteAlias, } = checkSiteFromConfig();

export default function GoogleTagLabelsNoScripts() {
  const scriptId = scriptIds[siteAlias];
  const isLabel = useIsLabel();

  if (!scriptId || !isLabel) return null;

  return (
    <React.Fragment>
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${scriptId}`}
          height="0"
          width="0"
          title={scriptId}
          style={{ display: 'none', visibility: 'hidden', }}
        />
      </noscript>
    </React.Fragment>
  );
}
